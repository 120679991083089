import React from 'react'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout title="404">
    <section class="section text-center">
      <h1>
        <strong>A keresett oldal nem található</strong>
      </h1>
    </section>
  </Layout>
)

export default NotFoundPage
